import v1_master_img from '../assets/images/v1_master.png'
import v1_first_img from '../assets/images/v1_first.jpg'
import v1_second_img from '../assets/images/v1_second.jpg'
import v1_third_img from '../assets/images/v1_third.jpg'

import v2_master_img from '../assets/images/v2_master.png'
import v2_first_img from '../assets/images/v2_first.jpg'
import v2_second_img from '../assets/images/v2_second.png'
import v2_third_img from '../assets/images/v2_third.jpg'

import v3_master_img from '../assets/images/v3_master.png'
import v3_first_img from '../assets/images/v3_first.jpg'
import v3_second_img from '../assets/images/v3_second.jpg'
import v3_third_img from '../assets/images/v3_third.jpg'

import v4_master_img from '../assets/images/v4_master.jpg'
import v4_first_img from '../assets/images/v4_first.jpg'
import v4_second_img from '../assets/images/v4_second.jpg'
import v4_third_img from '../assets/images/v4_third.jpg'

import v5_master_img from '../assets/images/v5_master.png'
import v5_first_img from '../assets/images/v5_first.jpg'
import v5_second_img from '../assets/images/v5_second.jpg'
import v5_third_img from '../assets/images/v5_third.jpg'

import v6_master_img from '../assets/images/v6_master.png'
import v6_first_img from '../assets/images/v6_first.jpg'
import v6_second_img from '../assets/images/v6_second.jpg'
import v6_third_img from '../assets/images/v6_third.jpg'

import v7_master_img from '../assets/images/v7_master.png'
import v7_first_img from '../assets/images/v7_first.jpg'
import v7_second_img from '../assets/images/v7_second.jpg'
import v7_third_img from '../assets/images/v7_third.jpg'

import v8_master_img from '../assets/images/v8_master.png'
import v8_first_img from '../assets/images/v8_first.jpg'
import v8_second_img from '../assets/images/v8_second.jpg'
import v8_third_img from '../assets/images/v8_third.jpg'

import v9_master_img from '../assets/images/v9_master.png'
import v9_first_img from '../assets/images/v9_first.jpg'
import v9_second_img from '../assets/images/v9_second.jpg'
import v9_third_img from '../assets/images/v9_third.jpg'

import v10_master_img from '../assets/images/v10_master.png'
import v10_first_img from '../assets/images/v10_first.jpg'
import v10_second_img from '../assets/images/v10_second.jpg'
import v10_third_img from '../assets/images/v10_third.jpg'

import v11_master_img from '../assets/images/v11_master.png'
import v11_first_img from '../assets/images/v11_first.jpg'
import v11_second_img from '../assets/images/v11_second.jpg'
import v11_third_img from '../assets/images/v11_third.jpg'

import v12_master_img from '../assets/images/v12_master.png'
import v12_first_img from '../assets/images/v12_first.jpg'
import v12_second_img from '../assets/images/v12_second.jpg'
import v12_third_img from '../assets/images/v12_third.jpg'

import v13_master_img from '../assets/images/v13_master.png'
import v13_first_img from '../assets/images/v13_first.png'
import v13_second_img from '../assets/images/v13_second.png'
import v13_third_img from '../assets/images/v13_third.jpg'

import v14_master_img from '../assets/images/v14_master.png'
import v14_first_img from '../assets/images/v14_first.jpg'
import v14_second_img from '../assets/images/v14_second.png'
import v14_third_img from '../assets/images/v14_third.jpg'

import v15_master_img from '../assets/images/v15_master.png'
import v15_first_img from '../assets/images/v15_first.jpg'
import v15_second_img from '../assets/images/v15_second.jpg'
import v15_third_img from '../assets/images/v15_third.jpg'

let vege_product = [
    {
      id: 'v1',
      name: "Leeks 250g",
      description: "Lanka Leeks",
      si_name: "ලීක්ස්  250g ",
      si_description: "ලීක්ස්.",
      master_image: v1_master_img,
      first_image: v1_first_img,
      second_image: v1_second_img,
      third_image: v1_third_img,
      price: 90.00,
      is_packed: false,
    },
    {
      id: 'v2',
      name: "Beans 250g",
      description: "Lanka Beans",
      si_name: "බෝංචි 250g",
      si_description: "බෝංචි",
      master_image: v2_master_img,
      first_image: v2_first_img,
      second_image: v2_second_img,
      third_image: v2_third_img,
      price: 130.00,
      is_packed: false,
    },
    {
        id: 'v3',
        name: "Carrot 250g",
        description: "Lanka Beans",
        si_name: "කැරට් 250g",
        si_description: "කැරට්",
        master_image: v3_master_img,
        first_image: v3_first_img,
        second_image: v3_second_img,
        third_image: v3_third_img,
        price: 70.00,
        is_packed: false,
      },
      {
        id: 'v4',
        name: "Pumpkin 250g",
        description: "Lanka Pumpkin",
        si_name: "වට්ටක්කා 250g",
        si_description: "වට්ටක්කා",
        master_image: v4_master_img,
        first_image: v4_first_img,
        second_image: v4_second_img,
        third_image: v4_third_img,
        price: 40.00,
        is_packed: false,
      },
      {
        id: 'v5',
        name: "Capsicum 250g",
        description: "Lanka Capsicum",
        si_name: "මාලුමිරිස් 250g",
        si_description: "මාලුමිරිස්",
        master_image: v5_master_img,
        first_image: v5_first_img,
        second_image: v5_second_img,
        third_image: v5_third_img,
        price: 120.00,
        is_packed: false,
      },
      {
        id: 'v6',
        name: "Tomato 250g",
        description: "Lanka Tomato",
        si_name: "තක්කාලි",
        si_description: "නැවුම් සහ රසවත් තක්කාලි.",
        master_image: v6_master_img,
        first_image: v6_first_img,
        second_image: v6_second_img,
        third_image: v6_third_img,
        price: 70.00,
        is_packed: false,
      },
      {
        id: 'v7',
        name: "Green Chilli 100g",
        description: "Lanka Tomato",
        si_name: "අමු මිරිස් 100g",
        si_description: "අමු මිරිස්",
        master_image: v7_master_img,
        first_image: v7_first_img,
        second_image: v7_second_img,
        third_image: v7_third_img,
        price: 60.00,
        is_packed: false,
      },
      {
        id: 'v8',
        name: "Cabbage 250g",
        description: "Lanka Cabbage",
        si_name: "ගෝවා 250g",
        si_description: "ගෝවා 250g  මිල මෙහි දක්වා ඇති අතර ඔබට ඊට වැඩි ප්‍රමාණයක් අවශ්‍ය නම් පහලින් ප්‍රමාණය තෝරන්න. ඔබට 500g අවශ්‍ය නම් 2 ද, 1kg ක් අවශ්‍ය නම් 4 ද තෝරන්න.",
        master_image: v8_master_img,
        first_image: v8_first_img,
        second_image: v8_second_img,
        third_image: v8_third_img,
        price: 25.00,
        is_packed: false,
      },
      {
        id: 'v9',
        name: "Beat root 250g",
        description: "Lanka Cabbage",
        si_name: "බීට් රූට් 250g",
        si_description: "බීට් රූට් 250g  මිල මෙහි දක්වා ඇති අතර ඔබට ඊට වැඩි ප්‍රමාණයක් අවශ්‍ය නම් පහලින් ප්‍රමාණය තෝරන්න. ඔබට 500g අවශ්‍ය නම් 2 ද, 1kg ක් අවශ්‍ය නම් 4 ද තෝරන්න.",
        master_image: v9_master_img,
        first_image: v9_first_img,
        second_image: v9_second_img,
        third_image: v9_third_img,
        price: 75.00,
        is_packed: false,
      },
      {
        id: 'v10',
        name: "Bitter Gourd 250g",
        description: "Lanka Bitter Gourd",
        si_name: "කරවිල",
        si_description: "දේශීය කරවිල.",
        master_image: v10_master_img,
        first_image: v10_first_img,
        second_image: v10_second_img,
        third_image: v10_third_img,
        price: 110.00,
        is_packed: false,
      },
      {
        id: 'v11',
        name: "Long Beans 250g",
        description: "Lanka Bitter Gourd",
        si_name: "මෑ කරල්  250g ",
        si_description: "දේශීය මෑ කරල්.",
        master_image: v11_master_img,
        first_image: v11_first_img,
        second_image: v11_second_img,
        third_image: v11_third_img,
        price: 80.00,
        is_packed: false,
      },
      {
        id: 'v12',
        name: "Brinjal 250g",
        description: "Lanka Bitter Gourd",
        si_name: "වම්බටු 250g",
        si_description: "දේශීය වම්බටු.",
        master_image: v12_master_img,
        first_image: v12_first_img,
        second_image: v12_second_img,
        third_image: v12_third_img,
        price: 75.00,
        is_packed: false,
      },
      {
      id: 'v13',
      name: "Potatoes 250g",
      description: "Lanka Potatoes",
      si_name: "ලංකා අල 250g",
      si_description: "ලංකා අල.",
      master_image: v13_master_img,
      first_image: v13_first_img,
      second_image: v13_second_img,
      third_image: v13_third_img,
      price: 90.00,
      is_packed: false,
  },
  {
    id: 'v14',
    name: "Red Onion 250g",
    description: "Red Onion 250g",
    si_name: "රතු ළූණු 250g",
    si_description: "රතු ළූණු.",
    master_image: v14_master_img,
    first_image: v14_first_img,
    second_image: v14_second_img,
    third_image: v14_third_img,
    price: 110.00,
    is_packed: false,
  },
  {
    id: 'v15',
    name: "Garlic 250g",
    description: "Garlic 250g",
    si_name: "සුදු ළූණු 250g ",
    si_description: "සුදු ළූණු.",
    master_image: v15_master_img,
    first_image: v15_first_img,
    second_image: v15_second_img,
    third_image: v15_third_img,
    price: 140.00,
    is_packed: false,
  },
];

export default vege_product;