import React, { useContext, useState } from 'react';
import { CartContext } from '../../CartContext';
import { useNavigate } from 'react-router-dom';
import './ShippingDetails.css';
import emailjs from 'emailjs-com';
import { useLanguage } from '../../LanguageContext';

const ShippingDetails = () => {
  const { texts,language } = useLanguage();
  const { cart, clearCart } = useContext(CartContext);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameError, setNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const getTotalAmount = () => {
    return cart.reduce((total, item) => total + item.price, 0).toFixed(2);
  };

  const validateForm = () => {
    let valid = true;

    if (name.trim() === '') {
      setNameError('Name is required');
      valid = false;
    } else {
      setNameError('');
    }

    if (address.trim() === '') {
      setAddressError('Address is required');
      valid = false;
    } else {
      setAddressError('');
    }

    const mobileNumberPattern = /^07\d{8}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      setMobileNumberError('Mobile number must be a 10-digit number starting with 07');
      valid = false;
    } else {
      setMobileNumberError('');
    }

    return valid;
  };

  const handleConfirmOrder = () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true); // Set loading to true when starting the process

    const sanitizedCart = cart.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price.toFixed(2),
      }));
      
    const orderDetails = {
      name,
      address,
      mobileNumber,
      cart: sanitizedCart,
      totalAmount: getTotalAmount(),
    };

    // Send email using EmailJS
    const templateParams = {
      name: orderDetails.name,
      address: orderDetails.address,
      mobileNumber: orderDetails.mobileNumber,
      orderDetails: JSON.stringify(orderDetails.cart, null, 2),
      totalAmount: orderDetails.totalAmount,
      to_email: 'ikrprasad@gmail.com', // Your email address
    };

    emailjs.send('service_nqg5le3', 'template_denl4zd', templateParams, 'FOsE_Sfw6IHiMgBJt')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Order confirmed and email sent!');
        clearCart();
        navigate('/');
      }, (err) => {
        console.log('FAILED...', err);
        alert('Failed to send email. Please try again.');
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the process
      });
  };

  return (
    <div className="shipping-container">
      <div className="shipping-details">
        <h2>{texts.shippingDetails.deliveryTitle}</h2>
        <p>
        {texts.shippingDetails.details}
        </p>
        <form>
          <div className="form-row">
            <label>{texts.shippingDetails.name}:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="form-row">
            <label>{texts.shippingDetails.address}:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            {addressError && <p className="error">{addressError}</p>}
          </div>
          <div className="form-row">
            <label>{texts.shippingDetails.mobileNumber}:</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="0712027708"
              required
            />
            {mobileNumberError && <p className="error">{mobileNumberError}</p>}
          </div>
          <div className="form-actions">
            <button
              type="button"
              className="confirm-button"
              onClick={handleConfirmOrder}
              disabled={loading}
            >
              {loading ? texts.shippingDetails.submiting : texts.shippingDetails.confirm}
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate('/')}
            >
              {texts.shippingDetails.cancel}
            </button>
          </div>
        </form>
      </div>
      <div className="order-summary">
        <h2>{texts.shippingDetails.orderSummary}</h2>
        <table>
          <thead>
            <tr>
              <th>{texts.shippingDetails.name}</th>
              <th>{texts.shippingDetails.quantity}</th>
              <th>{texts.shippingDetails.amount}</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>RS. {item.price.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>{texts.shippingDetails.totalAmount}. {getTotalAmount()}</h3>
      </div>
    </div>
  );
};

export default ShippingDetails;
