import React from 'react';
import VegeProductListing from '../components/ProductListing/VegeProductListing';

const VegetableShop = () => {
  return (
    <div>
      <VegeProductListing/>
    </div>
  );
};

export default VegetableShop;