// src/data/groceryData.js
import g1_master_img from '../assets/images/g1_master.png'
import g1_first_img from '../assets/images/g1_first.png'
import g1_second_img from '../assets/images/g1_second.jpg'
import g1_third_img from '../assets/images/g1_third.jpg'

import g2_master_img from '../assets/images/g2_master.png'
import g2_first_img from '../assets/images/g2_first.png'
import g2_second_img from '../assets/images/g2_second.png'
import g2_third_img from '../assets/images/g2_third.png'

import g3_master_img from '../assets/images/g3_master.jpg'
import g3_first_img from '../assets/images/g3_first.jpg'
import g3_second_img from '../assets/images/g3_second.jpg'
import g3_third_img from '../assets/images/g3_third.jpg'

import g4_master_img from '../assets/images/g4_master.png'
import g4_first_img from '../assets/images/g4_first.png'
import g4_second_img from '../assets/images/g4_second.png'
import g4_third_img from '../assets/images/g4_third.jpg'

import g5_master_img from '../assets/images/g5_master.png'
import g5_first_img from '../assets/images/g5_first.png'
import g5_second_img from '../assets/images/g5_second.png'
import g5_third_img from '../assets/images/g5_third.jpg'

import g6_master_img from '../assets/images/g6_master.png'
import g6_first_img from '../assets/images/g6_first.png'
import g6_second_img from '../assets/images/g6_second.png'
import g6_third_img from '../assets/images/g6_third.png'

import g7_master_img from '../assets/images/g7_master.png'
import g7_first_img from '../assets/images/g7_first.png'
import g7_second_img from '../assets/images/g7_second.png'
import g7_third_img from '../assets/images/g7_third.jpeg'

import g8_master_img from '../assets/images/g8_master.png'
import g8_first_img from '../assets/images/g8_first.png'
import g8_second_img from '../assets/images/g8_second.jpg'
import g8_third_img from '../assets/images/g8_third.jpg'

import g9_master_img from '../assets/images/g9_master.png'
import g9_first_img from '../assets/images/g9_first.jpg'
import g9_second_img from '../assets/images/g8_second.jpg'
import g9_third_img from '../assets/images/g9_third.png'

import g10_master_img from '../assets/images/g10_master.png'
import g10_first_img from '../assets/images/g10_first.png'
import g10_second_img from '../assets/images/g10_second.png'
import g10_third_img from '../assets/images/g8_second.jpg'

import g11_master_img from '../assets/images/g11_master.png'
import g11_first_img from '../assets/images/g11_first.png'
import g11_second_img from '../assets/images/g11_second.png'
import g11_third_img from '../assets/images/g11_third.png'

import g12_master_img from '../assets/images/g12_master.png'
import g12_first_img from '../assets/images/g11_first.png'
import g12_second_img from '../assets/images/g11_second.png'
import g12_third_img from '../assets/images/g11_third.png'

import g13_master_img from '../assets/images/g13_master.png'
import g13_first_img from '../assets/images/g11_first.png'
import g13_second_img from '../assets/images/g11_second.png'
import g13_third_img from '../assets/images/g11_third.png'

import g14_master_img from '../assets/images/g14_master.png'
import g14_first_img from '../assets/images/g14_first.png'
import g14_second_img from '../assets/images/g14_second.jpg'
import g14_third_img from '../assets/images/g14_third.jpg'

import g15_master_img from '../assets/images/g15_master.png'
import g15_first_img from '../assets/images/g15_first.jpg'
import g15_second_img from '../assets/images/g15_second.jpg'
import g15_third_img from '../assets/images/g15_third.jpg'

import g16_master_img from '../assets/images/g16_master.png'
import g16_first_img from '../assets/images/g16_first.jpg'
import g16_second_img from '../assets/images/g16_second.png'
import g16_third_img from '../assets/images/g16_third.jpg'

import g17_master_img from '../assets/images/g17_master.png'
import g17_first_img from '../assets/images/g17_first.jpg'
import g17_second_img from '../assets/images/g17_second.jpg'
import g17_third_img from '../assets/images/g17_third.jpg'

import g18_master_img from '../assets/images/g18_master.png'
import g18_first_img from '../assets/images/g18_first.jpeg'
import g18_second_img from '../assets/images/g18_second.png'
import g18_third_img from '../assets/images/g18_third.jpg'

import g19_master_img from '../assets/images/g19_master.png'
import g19_first_img from '../assets/images/g19_first.jpg'
import g19_second_img from '../assets/images/g19_second.jpg'
import g19_third_img from '../assets/images/g19_third.jpg'

import g20_master_img from '../assets/images/g20_master.png'
import g20_first_img from '../assets/images/g20_first.jpg'
import g20_second_img from '../assets/images/g20_second.jpg'
import g20_third_img from '../assets/images/g20_third.jpg'

import g21_master_img from '../assets/images/g21_master.jpg'
import g21_first_img from '../assets/images/g21_first.jpg'
import g21_second_img from '../assets/images/g21_second.jpg'
import g21_third_img from '../assets/images/g21_third.jpg'

import v13_master_img from '../assets/images/v13_master.png'
import v13_first_img from '../assets/images/v13_first.png'
import v13_second_img from '../assets/images/v13_second.png'
import v13_third_img from '../assets/images/v13_third.jpg'

import v14_master_img from '../assets/images/v14_master.png'
import v14_first_img from '../assets/images/v14_first.jpg'
import v14_second_img from '../assets/images/v14_second.png'
import v14_third_img from '../assets/images/v14_third.jpg'

import v15_master_img from '../assets/images/v15_master.png'
import v15_first_img from '../assets/images/v15_first.jpg'
import v15_second_img from '../assets/images/v15_second.jpg'
import v15_third_img from '../assets/images/v15_third.jpg'

let data_product = [
  {
    id: 'g1',
    name: "Ameliya Tea 100g",
    description: "Tasty and high quality CTC tea.",
    si_name: "අමේලියා තේ 100g",
    si_description: "උසස් තත්ත්වයේ CTC තේ.",
    master_image: g1_master_img,
    first_image: g1_first_img,
    second_image: g1_second_img,
    third_image: g1_third_img,
    price: 180.00,
    is_packed: true,
  },
  {
    id: 'g2',
    name: "Chilly 100g",
    description: "High quality Chilli powder.100% pure.",
    si_name: "මිරිස් කුඩු 100g",
    si_description: "උසස් තත්ත්වයේ මිරිස් කුඩු.නටු ඉවත් කර, හොඳින් සෝදා ගන්නා ලද වියළි මිරිස් පමණක් අඩංගු වේ.",
    master_image: g2_master_img,
    first_image: g2_first_img,
    second_image: g2_second_img,
    third_image: g2_third_img,
    price: 140.00,
    is_packed: true,
  },
  {
    id: 'g3',
    name: "Curry Powder 50g",
    description: "High quality Curry Powder. Ingrediants : Coriander, Cummin Seeds, Fennel Seeds, Turmeric, Cinnamon, Black pepper, Fenugreek and Cardamom  ",
    si_name: "තුනපහ කුඩු 50g",
    si_description: "උසස් තත්ත්වයේ තුනපහ කුඩු. අඩංගු ද්‍රව්‍ය : කොත්තමල්ලි, සූදුරු, මහදුරු, කහ, කුරුදු, ගම්මිරිස්, උළුහාල්, එනසාල්.",
    master_image: g3_master_img,
    first_image: g3_first_img,
    second_image: g3_second_img,
    third_image: g3_third_img,
    price: 70.00,
    is_packed: true,
  },
  {
    id: 'g4',
    name: "Turmeric Powder 50g",
    description: "High quality Turmeric Powder. 100% clean and dried sri lankan turmeric.",
    si_name: "කහ කුඩු 50g",
    si_description: "උසස් තත්ත්වයේ කහ කුඩු.100% පිරිසිදු කොට වියළන ලද දේශීය කහ පමණක් අඩංගු වේ. ",
    master_image: g4_master_img,
    first_image: g4_first_img,
    second_image: g4_second_img,
    third_image: g4_third_img,
    price: 75.00,
    is_packed: true,
  },
  {
    id: 'g5',
    name: "Curry Powder 100g",
    description: "High quality Curry Powder. Ingrediants : Coriander, Cummin Seeds, Fennel Seeds, Turmeric, Cinnamon, Black pepper, Fenugreek and Cardamom  ",
    si_name: "තුනපහ කුඩු 100g",
    si_description: "උසස් තත්ත්වයේ තුනපහ කුඩු. අඩංගු ද්‍රව්‍ය : කොත්තමල්ලි, සූදුරු, මහදුරු, කහ, කුරුදු, ගම්මිරිස්, උළුහාල්, එනසාල්.",
    master_image: g5_master_img,
    first_image: g5_first_img,
    second_image: g5_second_img,
    third_image: g5_third_img,
    price: 145.00,
    is_packed: true,
  },
  {
    id: 'g6',
    name: "Raththi Milk Powder 400g",
    description: "Raththi Milk Powder 400g",
    si_name: "රත්ති කිරිපිටි 400g",
    si_description: "රත්ති කිරිපිටි 400g",
    master_image: g6_master_img,
    first_image: g6_first_img,
    second_image: g6_second_img,
    third_image: g6_third_img,
    price: 1080.00,
    is_packed: true,
  },
  {
    id: 'g7',
    name: "Pelawatta Milk Powder",
    description: "Pelawatta Milk Powder 400g",
    si_name: "පැලවත්ත කිරිපිටි",
    si_description: "පැලවත්ත කිරිපිටි 400g",
    master_image: g7_master_img,
    first_image: g7_first_img,
    second_image: g7_second_img,
    third_image: g7_third_img,
    price: 1080.00,
    is_packed: true,
  },
  {
    id: 'g8',
    name: "NAN 1 Milk Powder 300g",
    description: "NAN 1 Milk Powder",
    si_name: "NAN 1 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g8_master_img,
    first_image: g8_first_img,
    second_image: g8_second_img,
    third_image: g8_third_img,
    price: 2400.00,
    is_packed: true,
  },
  {
    id: 'g9',
    name: "NAN 2 Milk Powder 300g",
    description: "NAN 2 Milk Powder",
    si_name: "NAN 2 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g9_master_img,
    first_image: g9_first_img,
    second_image: g9_second_img,
    third_image: g9_third_img,
    price: 2400.00,
    is_packed: true,
  },
  {
    id: 'g10',
    name: "NAN 3 Milk Powder 300g",
    description: "NAN 3 Milk Powder",
    si_name: "NAN 3 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g10_master_img,
    first_image: g10_first_img,
    second_image: g10_second_img,
    third_image: g10_third_img,
    price: 2000.00,
    is_packed: true,
  },
  {
    id: 'g11',
    name: "LACTOGEN 1 Milk Powder 300g",
    description: "LACTOGEN 1 Milk Powder",
    si_name: "LACTOGEN 1 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g11_master_img,
    first_image: g11_first_img,
    second_image: g11_second_img,
    third_image: g11_third_img,
    price: 1950.00,
    is_packed: true,
  },
  {
    id: 'g12',
    name: "LACTOGEN 2 Milk Powder 300g",
    description: "LACTOGEN 2 Milk Powder",
    si_name: "LACTOGEN 2 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g12_master_img,
    first_image: g12_first_img,
    second_image: g12_second_img,
    third_image: g12_third_img,
    price: 1950.00,
    is_packed: true,
  },
  {
    id: 'g13',
    name: "LACTOGEN 3 Milk Powder 300g",
    description: "LACTOGEN 3 Milk Powder",
    si_name: "LACTOGEN 3 ළදරු කිරිපිටි",
    si_description: "ළදරු කිරිපිටි 300g",
    master_image: g13_master_img,
    first_image: g13_first_img,
    second_image: g13_second_img,
    third_image: g13_third_img,
    price: 1200.00,
    is_packed: true,
  },
  {
    id: 'g14',
    name: "Araliya Keeri Samba 5kg",
    description: "Araliya Keeri Samba",
    si_name: "අරලිය කීරි සම්බා 5kg",
    si_description: "අරලිය කීරි සම්බා 5kg",
    master_image: g14_master_img,
    first_image: g14_first_img,
    second_image: g14_second_img,
    third_image: g14_third_img,
    price: 1300.00,
    is_packed: true,
  },

  {
    id: 'g15',
    name: "Araliya Sudu Kekulu 5kg",
    description: "Araliya Sudu Kekulu",
    si_name: "අරලිය සුදු කැකුළු 5kg",
    si_description: "අරලිය සුදු කැකුළු 5kg",
    master_image: g15_master_img,
    first_image: g15_first_img,
    second_image: g15_second_img,
    third_image: g15_third_img,
    price: 1050.00,
    is_packed: true,
  },

  {
    id: 'g16',
    name: "Araliya Rathu Kekulu 5kg",
    description: "Araliya Rathu Kekulu 5kg",
    si_name: "අරලිය රතු කැකුළු 5kg",
    si_description: "අරලිය රතු කැකුළු 5kg",
    master_image: g16_master_img,
    first_image: g16_first_img,
    second_image: g16_second_img,
    third_image: g16_third_img,
    price: 1050.00,
    is_packed: true,
  },
  {
    id: 'g17',
    name: "Red Dhal 250g",
    description: "Red Dhal 250g",
    si_name: "පරිප්පු 250g",
    si_description: "පරිප්පු 250g",
    master_image: g17_master_img,
    first_image: g17_first_img,
    second_image: g17_second_img,
    third_image: g17_third_img,
    price: 110.00,
    is_packed: false,
  },
  {
    id: 'g18',
    name: "Green Gram 250g",
    description: "Green Gram 250g",
    si_name: "මුං ඇට 250g",
    si_description: "මුං ඇට 250g",
    master_image: g18_master_img,
    first_image: g18_first_img,
    second_image: g18_second_img,
    third_image: g18_third_img,
    price: 235.00,
    is_packed: false,
  },
  {
    id: 'g19',
    name: "Kadala 250g",
    description: "Kadala 250g",
    si_name: "කඩල 250g",
    si_description: "කඩල 250g",
    master_image: g19_master_img,
    first_image: g19_first_img,
    second_image: g19_second_img,
    third_image: g19_third_img,
    price: 235.00,
    is_packed: false,
  },
  {
    id: 'g20',
    name: "White Sugar 250g",
    description: "White Sugar 250g",
    si_name: "සුදු සීනි 250g",
    si_description: "සුදු සීනි 250g",
    master_image: g20_master_img,
    first_image: g20_first_img,
    second_image: g20_second_img,
    third_image: g20_third_img,
    price: 70.00,
    is_packed: false,
  },
  {
    id: 'g21',
    name: "Brown Sugar 250g",
    description: "Brown Sugar 250g",
    si_name: "රතු සීනි 250g",
    si_description: "රතු සීනි 250g",
    master_image: g21_master_img,
    first_image: g21_first_img,
    second_image: g21_second_img,
    third_image: g21_third_img,
    price: 105.00,
    is_packed: false,
  },
  {
    id: 'v13',
    name: "Potatoes 250g",
    description: "Lanka Potatoes",
    si_name: "ලංකා අල 250g",
    si_description: "ලංකා අල.",
    master_image: v13_master_img,
    first_image: v13_first_img,
    second_image: v13_second_img,
    third_image: v13_third_img,
    price: 90.00,
    is_packed: false,
  },
  {
    id: 'v14',
    name: "Red Onion 250g",
    description: "Red Onion 250g",
    si_name: "රතු ළූණු 250g",
    si_description: "රතු ළූණු.",
    master_image: v14_master_img,
    first_image: v14_first_img,
    second_image: v14_second_img,
    third_image: v14_third_img,
    price: 110.00,
    is_packed: false,
  },
  {
    id: 'v15',
    name: "Garlic 250g",
    description: "Garlic 250g",
    si_name: "සුදු ළූණු 250g ",
    si_description: "සුදු ළූණු.",
    master_image: v15_master_img,
    first_image: v15_first_img,
    second_image: v15_second_img,
    third_image: v15_third_img,
    price: 140.00,
    is_packed: false,
  },
  
];

export default data_product;
