// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useLanguage } from '../../LanguageContext';

const Footer = () => {
  const { texts } = useLanguage();

  return (
    <footer className="footer">
      <div className="footer-links">
      <Link to="/aboutUs" className="footer-item">{texts.footer.aboutUs}</Link>
        <a href="https://www.facebook.com" className="footer-item">{texts.footer.facebook}</a>
        <a href="https://www.youtube.com" className="footer-item">{texts.footer.youtube}</a>
      </div>
      <div className="footer-copyright">
      Copyright @2024. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
