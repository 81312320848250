import React from 'react'
import CartDetails from '../components/CartDetails/CartDetails'

const CartDetailsPage = () => {
  return (
    <div>
      <CartDetails/>
    </div>
  )
}

export default CartDetailsPage
