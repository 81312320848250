import React, { useState, useContext } from 'react';
import './ProductDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import groceryData from '../../data/groceryData';
import vegetableData from '../../data/vegetableData';
import { CartContext } from '../../CartContext';
import { useLanguage } from '../../LanguageContext';

const ProductDetails = () => {
    const { texts,language } = useLanguage();
    const { id,category  } = useParams();
    const data_product = category === 'grocery' ? groceryData : vegetableData;
     // Assume getProductById is a function to fetch product details from the data file
     const getProductById = (id) => {
        return data_product.find((product) => product.id === id);
    };
    const product = getProductById(id);
    
    const { addToCart } = useContext(CartContext);
    const [selectedQuantity, setSelectedQuantity] = useState(1); // Default quantity
    const [calculatedAmount, setCalculatedAmount] = useState(product.price); // Default calculated amount
    const [mainImage, setMainImage] = useState(''); // State for main image
    const navigate = useNavigate(); // Get navigate function

   

    

    const handleQuantityChange = (event) => {
        const quantity = parseInt(event.target.value);
        let amount;
        // if (product.is_packed) {
            amount = product.price * quantity;
        // } else {
        //     amount = (product.price / 250) * quantity;
        // }
        setSelectedQuantity(quantity);
        setCalculatedAmount(amount);
    };

    const handleAddToCart = () => {
        addToCart({ ...product, quantity: selectedQuantity, price: calculatedAmount });
        navigate('/cart'); // Navigate to cartdetails page
    };

    // Initialize main image when product data is loaded
    React.useEffect(() => {
        if (product) {
            setMainImage(product.master_image);
        }
    }, [product]);

    const handleImageClick = (image) => {
        setMainImage(image);
    };

    return (
        <div className="product-details">
            <div className="image-gallery">
                <img src={mainImage} alt={product.name} className="main-image" />
                <div className="thumbnail-images">
                    <img
                        src={product.master_image}
                        alt={product.name}
                        onClick={() => handleImageClick(product.master_image)}
                    />
                    <img
                        src={product.first_image}
                        alt={product.name}
                        onClick={() => handleImageClick(product.first_image)}
                    />
                    <img
                        src={product.second_image}
                        alt={product.name}
                        onClick={() => handleImageClick(product.second_image)}
                    />
                    <img
                        src={product.third_image}
                        alt={product.name}
                        onClick={() => handleImageClick(product.third_image)}
                    />
                </div>
            </div>
            <div className="product-info">
                <h2>{language === 'si' ? product.si_name : product.name}</h2>
                <p>{language === 'si' ? product.si_description : product.description}</p>
                <p>{texts.productDetails.price}. {product.price}</p>
                <label htmlFor="quantity">{texts.productDetails.selectQuantity}:  {product.is_packed ? "" : texts.productDetails.selectQuantityDes}</label>
                <select id="quantity" value={selectedQuantity} onChange={handleQuantityChange}>
                    {product.is_packed ? (
                        <>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </>
                    ) : (
                        <>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </>
                        // <>
                        //     <option value={250}>250g</option>
                        //     <option value={500}>500g</option>
                        //     <option value={1000}>1kg</option>
                        //     <option value={2000}>2kg</option>
                        //     <option value={3000}>3kg</option>
                        //     <option value={4000}>4kg</option>
                        //     <option value={5000}>5kg</option>
                        //     <option value={10000}>10kg</option>
                        // </>
                    )}
                </select>
                <p>{texts.productDetails.selectedQuantity}: {product.is_packed ? selectedQuantity : `${selectedQuantity}`}</p>
                <p>{texts.productDetails.priceForSelectedQuantity}. {calculatedAmount.toFixed(2)}</p>
                <button onClick={handleAddToCart} className="add-to-cart-button">{texts.productDetails.addToCart}</button>
            </div>
        </div>
    );
};

export default ProductDetails;
