// src/components/ProductListing/ProductListing.js
import React from 'react';
import vege_product from '../../data/vegetableData';
import './VegeProductListing.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';

const VegeProductListing = () => {
    const { language } = useLanguage();
  return (
    <div className="product-listing">
      {vege_product.map(product => (
        <Link to={`/product/vegetable/${product.id}`} className="product-card-link">
        <div className="product-card" key={product.id}>
          <img src={product.master_image} alt={product.name} />
          <h4>{language === 'si' ? product.si_name : product.name}</h4>
          <p>Rs : {product.price}</p>
        </div>
        </Link>
        
      ))}
    </div>
  );
};

export default VegeProductListing;
