// src/LanguageContext.js
import React, { createContext, useState, useContext } from 'react';
import en from './locales/en.json';
import si from './locales/si.json';

const LanguageContext = createContext();

const translations = {
  en,
  si
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [texts, setTexts] = useState(translations[language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setTexts(translations[lang]);
  };

  return (
    <LanguageContext.Provider value={{ language, texts, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
