import React from 'react';
import './Banner.css';
import { useLanguage } from '../../LanguageContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import kidsBanner from '../../assets/images/banner_1.png';
import mensBanner from '../../assets/images/banner_2.png';
import womenBanner from '../../assets/images/banner_3.png';

const Banner = () => {
  const { texts } = useLanguage();

  const text_1 = "ගන්න දේ ගෙදරටම";
  const text_2 = "Delivery ගාස්තුව රු : 300.00";
  const text_3 = "ගෙදර ඉඳං Order කරන්න,";
  const text_4 = "බඩු ලැබුණාම සල්ලි දෙන්න.";
  const text_5 = "මිලත් අඩුයි, බඩුත් හොඳයි...       ";
  const text_6 = "වෙලාවත් ඉතුරුයි.";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="banner_container">
      <Slider {...settings} className="banner">
      <div className="banner-slide">
          <img src={kidsBanner} alt="Kids Banner" className="banner-image" />
          <div className="banner-text">
            <p>{text_1}</p>
            <p>{text_2}</p>
          </div>
        </div>
        <div className="banner-slide">
          <img src={mensBanner} alt="Mens Banner" className="banner-image" />
          <div className="banner-text">
            <p>{text_3}</p>
            <p>{text_4}</p>
          </div>
        </div>
        <div className="banner-slide">
          <img src={womenBanner} alt="Women Banner" className="banner-image" />
          <div className="banner-text">
            <p>{text_5}</p>
            <p>{text_6}</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
