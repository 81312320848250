// src/components/ProductListing/ProductListing.js
import React from 'react';
import data_product from '../../data/groceryData';
import './ProductListing.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';
const ProductListing = () => {

    const { language } = useLanguage();

  return (
    <div className="product-listing">
      {data_product.map(product => (
        <Link to={`/product/grocery/${product.id}`} className="product-card-link">
        <div className="product-card" key={product.id}>
          <img src={product.master_image} alt={product.name} />
          <h4>{language === 'si' ? product.si_name : product.name}</h4>
          <p>Rs : {product.price}</p>
        </div>
        </Link>
        
      ))}
    </div>
  );
};

export default ProductListing;
