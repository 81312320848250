// src/pages/GroceryShop.js
import React from 'react';
import ProductListing from '../components/ProductListing/ProductListing';

const GroceryShop = () => {
  return (
    <div>
      <ProductListing />
    </div>
  );
};

export default GroceryShop;
