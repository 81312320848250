import React, { useContext } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import { CartContext } from '../../CartContext';
import './CartDetails.css';
import { useLanguage } from '../../LanguageContext';

const CartDetails = () => {
  const { texts,language } = useLanguage();
  const { cart, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();
  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const getTotalAmount = () => {
    const total = cart.reduce((total, item) => total + item.price, 0);
    return isNaN(total) ? '0.00' : total.toFixed(2);
  };

  return (
    <div className="cart-details">
      <h3>{texts.cartDetails.cartDetailsTitle}</h3>
      <table className="cart-table">
        <thead>
          <tr>
            <th>{texts.cartDetails.name}</th>
            <th>{texts.cartDetails.quantity}</th>
            <th>{texts.cartDetails.amount}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item) => (
            <tr key={item.id}>
              <td>{language === 'si' ? item.si_name : item.name}</td>
              <td>{item.quantity}</td>
              <td>Rs: {item.price.toFixed(2)}</td>
              <td className="action-column">
                <button className="remove-button" onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>{texts.cartDetails.totalAmount}.{getTotalAmount()}</h3>
      <div className="cart-buttons">
        <button className="back-button" onClick={() => navigate(-1)}>{texts.cartDetails.back}</button>
        <Link to="/shipping" className="proceed-button">{texts.cartDetails.proceed}</Link>
      </div>
    </div>
  );
};

export default CartDetails;
