import React, { useContext } from 'react';
import './Navbar.css';
import { useLanguage } from '../../LanguageContext';
import { Link } from 'react-router-dom';
import { CartContext } from '../../CartContext';
import cartIcon from '../../assets/images/cart_icon.png'; // Make sure the path is correct

const Navbar = () => {
  const { texts, changeLanguage } = useLanguage();
  const { cart } = useContext(CartContext);
  const cartItemCount = cart.reduce((count, item) => count + item.amount, 0);

  return (
    <nav className="navbar">
      <div className="navbar-logo"><h2>MyTown.lk</h2></div>
      <div className="navbar-menu">
        <Link to="/groceryShop" className="navbar-item">{texts.navbar.groceryShop}</Link>
        <Link to="/vegetableShop" className="navbar-item">{texts.navbar.vegetableShop}</Link>
      </div>
      <div className="navbar-actions">
        <div className="navbar-cart">
          <Link to="/cart">
            <img src={cartIcon} alt="Cart" className="cart-icon" />
            {/* {texts.navbar.cart} */}
            {cartItemCount > 0 && <span className="cart-count">Rs:{cartItemCount}</span>}
          </Link>
        </div>
        <select className="navbar-language" onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="si">සිංහල</option>
        </select>
      </div>
    </nav>
  );
};

export default Navbar;