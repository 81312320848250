import React from 'react'
import './AboutUs.css'
import { useLanguage } from '../../LanguageContext';

const AboutUs = () => {
    const { texts,language } = useLanguage();
  return (
    <div className='aboutus_container'>
        <h2>{texts.aboutUs.aboutUsTitle}</h2>
        <p> {texts.aboutUs.bodyText}
        
        </p><br/>
    <h3>{texts.aboutUs.contactUs}</h3>
    <p>
    {texts.aboutUs.mobile} : 0712027708<br/>
    {texts.aboutUs.email}විද්‍යුත් තැපැල් : ikrprasad@gmail.com 
    </p>
    </div>
  )
}

export default AboutUs
