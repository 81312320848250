// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Banner from './components/Banner/Banner';
import GroceryShop from './pages/GroceryShop';
import ProductDetails from './components/ProductDetails/ProductDetails';
import CartDetails from './pages/CartDetailsPage';
import ShippingDetails from './components/ShippingDetails/ShippingDetails';
import { LanguageProvider } from './LanguageContext';
import { CartProvider } from './CartContext';
import CartDetailsPage from './pages/CartDetailsPage';
import VegetableShop from './pages/VegetableShop';
import AboutUs from './components/AboutUs/AboutUs';

const App = () => {
  return (
    <LanguageProvider>
      <CartProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Banner />
          <div className='app_body'>
          <Routes>
            <Route path="/" element={<GroceryShop />} />
            <Route path="/groceryShop" element={<GroceryShop />} />
            <Route path="/vegetableShop" element={<VegetableShop/>} />
            <Route path="/product/:category/:id" element={<ProductDetails />} />
            <Route path="/cart" element={<CartDetailsPage />} />
            <Route path="/shipping" element={<ShippingDetails />} />
            <Route path="/aboutUs" element={<AboutUs/>} />
          </Routes>
          </div>
          
          <Footer />
        </div>
      </Router>
      </CartProvider>
      
    </LanguageProvider>
  );
};

export default App;
